
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesa extends PageBase { 

    service = new RelatorioService(); 
    item = new Relatorio();
    overlay: boolean = false;
    filter:any ={};
   
    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;
            
       this.service.ListarDespesaDetalhada(this.filter.formaPagamento, this.filter.empreendimentoId, this.filter.usuarioBaixa,this.filter.fornecedorId,this.filter.situacaoParcelaId,this.filter.tipoDespesaId, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataBaixaInicial, this.filter.dataBaixaFinal,this.filter.empresaId,this.filter.despesaId,this.filter.numeroDocumento, this.filter.centroCustoId, this.filter.contaGerencialId, this.filter.contaCorrenteId, this.filter.dataEmissaoInicial, this.filter.dataEmissaoFinal)
            .then(
                res => {   
                    this.item = res.data;
                 }, 
                err => {  
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }).finally(() => {
                this.overlay = false;
            });
    }
}

class Relatorio {
    public valorTotal:number = 0;
    public valorPagoTotal:number = 0;
    public jurosTotal:number = 0;
    public multaTotal:number = 0;
    public encargosTotal:number = 0;
    public descontoTotal:number = 0;
    public quantidadeParcelasTotal:number = 0;
    public parcelas: RelatorioParcela[] = [];
}

class RelatorioParcela {
    public parcelaId:number = 0;
    public numeroSequenciaContrato:string = '';
    public parcelaNumero:number = 0;
    public totalParcelas:number = 0;
    public fornecedorNome:string = '';
    public empreendimentoNome:string = '';
    public unidadeQuadra:string = '';
    public unidadeLote:string = '';
    public chaveDeAcesso: string ='';
    public tipoDespesa:string = '';
    public vencimentoParcela:string = '';
    public dataPagamentoParcela:string = '';
    public situacaoParcela:string = '';
    public valorParcela:number = 0;
    public formaPagamentoParcela:string = '';
    public jurosParcelaBaixa:number = 0;
    public multaParcelaBaixa:number = 0;
    public descontosParcelaBaixa:number = 0;
    public valorPagoParcela:number = 0;
    public valorTotalPagoParcela:number = 0;
    public usuarioBaixa:string = '';
    public empresaNome:string = '';
    public tipoAditamentoNome:string = '';
    public numeroDocumento:string ='';
    public despesaId:number =0;
    public centroCustoNome:string ='';
    public contaGerencialNome:string ='';
    public observacaoParcela:string = '';
    public contaCorrenteNome:string = '';
    public despesaObservacao:string = '';
    public fornecedorCNPJ:string = '';
    public despesaDataEmissao:string = '';
}

